import { render, staticRenderFns } from "./AdministratorDetail.vue?vue&type=template&id=993865fc&scoped=true"
import script from "./AdministratorDetail.vue?vue&type=script&lang=js"
export * from "./AdministratorDetail.vue?vue&type=script&lang=js"
import style0 from "./AdministratorDetail.vue?vue&type=style&index=0&id=993865fc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "993865fc",
  null
  
)

export default component.exports